import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { setAssetModalOpen } from "redux/actions";
import data from "data.js";
import "./index.scss";
import { ReactComponent as Down } from "assets/down.svg";
import { ReactComponent as X } from "assets/x.svg";
import convert from "color-convert";

Modal.setAppElement("#root")
const MIN_WIDTH = 2000
const AssetDownload = ({ isOpen, onRequestClose, assetName, assetMarkup, foregroundColor, secondaryColor, backgroundColor }) => {

  const [size,setSize] = useState({
    width: 2000,
    height: 2000,
    aspectRatio: 1
  })
  const [downloadUrls,setDownloadUrls] = useState(null)

  useEffect(() => {
    if (assetMarkup) {
      const viewBox = assetMarkup.match(/viewBox="[\d] [\d] ([.\d]*) ([.\d]*)"/);
      const aspectRatio = viewBox[1]/viewBox[2]
      let width = viewBox[1];
      let height = viewBox[2];

      if (width < MIN_WIDTH) {
        width = MIN_WIDTH
        height = width * aspectRatio
      }
      setSize({
        width,
        height,
        aspectRatio
      })
    }
  }, [assetMarkup])

  useEffect(() => {
    setSize({
      ...size,
      height: size.width/size.aspectRatio
    })
  }, [size.width])

  useEffect(() => {
    setSize({
      ...size,
      width: size.height*size.aspectRatio
    })
  }, [size.height])

  useEffect(() => {
    Promise.all([
      importSVG(markup,'png',size),
      importSVG(markup,'jpg',size)
    ]).then(values => {
      setDownloadUrls({
        svg: `data:image/svg+xml,${encodeURIComponent(markup)}`,
        png: values[0],
        jpg: values[1]
      })
    })
  }, [
    size.width,
    size.height,
    assetMarkup,
    foregroundColor.key,
    secondaryColor,
    backgroundColor.key
  ])

  // const logo = data.logo.items.find(item => item.name === assetName)
  // const StyledComponent = <logo.Component id="Download" xmlns="http://www.w3.org/2000/svg" />
  // const staticMarkup = renderToStaticMarkup(StyledComponent);

  if (!assetMarkup) {
    return null
  }
  //.replace('<?xml version="1.0" encoding="UTF-8"?>','').
  const staticMarkup = assetMarkup
  //.replace(/<style>.*?<\/style>/gms,'');
//   const splitStaticMarkup = staticMarkup.split(/(.*?)><(.*)/);
//
//   const markup = splitStaticMarkup[0] + `
//     >
//       <style>
//         svg#Download {
//           background-color: ${backgroundColor.hex};
//           fill: ${foregroundColor.hex};
//         }
//         ${secondaryColor ? `
//           svg#Download #secondary {
//             fill: ${secondaryColor.hex};
//           }
//         `: ''}
//       </style>
//       <rect x="0" y="0" width="${viewBoxDimensions}" height="${viewBoxDimensions}" fill="${backgroundColor.hex}" />
//     <`.trim() + splitStaticMarkup.slice(1,splitStaticMarkup.length - 1).join('><');

  const markup = staticMarkup;

  const downloadName = assetName;
  const bgRgb = convert.hex.rgb(data.color.items.heartland.hex);
  bgRgb.push(.9);

  return (
    <Modal
      style={{
        overlay: {
          zIndex: 3,
          backgroundColor: `rgba(${bgRgb.join(',')})`,
          padding: '5px'
        },
        content: {
          maxWidth: "400px",
          marginLeft: "auto",
          marginRight: "auto",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          bottom: "auto",
          right: "auto",
          width: "calc(100% - 10px)",
        }
      }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}>
      <div className="AssetDownload ff-industry">
        {assetMarkup && <div className="AssetDownload-preview">
          <div className="AssetDownload-preview-image" dangerouslySetInnerHTML={{ __html: markup}} />
          <div className="AssetDownload-preview-width">
            <div>{parseFloat(size.width.toFixed(2))}px</div>
          </div>
          <div className="AssetDownload-preview-height" style={{
            width: `calc(${100/size.aspectRatio}% - ${3/size.aspectRatio}rem)`
          }}>
            <div>{parseFloat(size.height.toFixed(2))}px</div>
          </div>
        </div>}
        <div className="AssetDownload-controls">
          <dl className="AssetDownload-controls-meta">
            <dt className="ff-aktiv">Size</dt>
            <dd><Sizes size={size} setSize={setSize} /></dd>
            <dt className="ff-aktiv">Background</dt>
            <dd>{backgroundColor.name}</dd>
            <dt className="ff-aktiv">Foreground</dt>
            <dd>{foregroundColor.name}</dd>
          </dl>
          <Formats downloadUrls={downloadUrls} downloadName={downloadName} />
        </div>
      </div>
      <button
        className="AssetDownload-close"
        onClick={onRequestClose}>
        <X />
      </button>
    </Modal>
  )
}

const mapStateToProps = state => {
  const colors = state.data.color.items;
  return {
    isOpen: state.assetDownload.modalIsOpen,
    assetName: state.assetDownload.assetName,
    assetMarkup: state.assetDownload.assetMarkup,
    foregroundColor: colors[state.sandbox.foregroundColor],
    secondaryColor: colors[state.sandbox.secondaryColor],
    backgroundColor: colors[state.sandbox.backgroundColor]
  }
}
const mapDispatchToProps = dispatch => ({
  onRequestClose: () => {
    dispatch(setAssetModalOpen(false))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(AssetDownload)

const Formats = ({ downloadUrls, downloadName }) => {
  return downloadUrls ? (
    <div className="AssetDownload-controls-format AssetDownload-controls-control--radio font-label">
      <DownloadFormat href={downloadUrls['svg']} name={downloadName}>SVG</DownloadFormat>
      <DownloadFormat href={downloadUrls['jpg']} name={downloadName}>JPG</DownloadFormat>
      <DownloadFormat href={downloadUrls['png']} name={downloadName}>PNG</DownloadFormat>
    </div>
  ) : "Loading"
}
const DownloadFormat = ({ name, href, children }) => (
  <a className="AssetDownload-link" href={href} download={name}>
    {children} <Down />
  </a>
)

const Sizes = ({ size, setSize }) => {
  return <div className="AssetDownload-controls-sizes">
    <Size dimension={"width"} label="H" size={size} setSize={setSize} />
    <Size dimension={"height"} label="W" size={size} setSize={setSize} />
  </div>
}

const Size = ({ dimension, label, size, setSize }) => {
  const value = parseFloat(size[dimension].toFixed(2));
  return (
    <label className="AssetDownload-controls-sizes-size">
      {label}
      <input
        type="text"
        increment="1"
        value={value}
        style={{ width: Math.max(3, (value.toString().length)*10 + 1) + 'px' }}
        onChange={(e) => {
          setSize({
            ...size,
            [dimension]: +e.target.value
          })
        }} />px
    </label>
  )
}

export const importSVG = (svg_xml,format, size) => {
  return new Promise((resolve,reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext('2d');
    context.imageSmoothingQuality = "high"
    canvas.width = size.width
    canvas.height = size.height

    const img = new Image();
    img.src = "data:image/svg+xml;base64," + btoa(svg_xml);

    img.onload = function() {
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      switch (format) {
        case 'png':
          resolve(canvas.toDataURL('image/png'))
          break;
        case 'jpg':
          resolve(canvas.toDataURL('image/jpeg', 1.0))
          break;
        default:
          reject('unsupported output format: ' + format)
      }
    }
  })
}