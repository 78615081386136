export const setSandboxMode = (value) => ({
  type: "SET_SANDBOX_MODE",
  value
})
export const setSandboxLogo = (value) => ({
  type: "SET_SANDBOX_LOGO",
  value
})
export const setSandboxForegroundColor = (value) => ({
  type: "SET_SANDBOX_FOREGROUND_COLOR",
  value
})
export const setSandboxSecondaryColor = (value) => ({
  type: "SET_SANDBOX_SECONDARY_COLOR",
  value
})
export const setSandboxBackgroundColor = (value) => ({
  type: "SET_SANDBOX_BACKGROUND_COLOR",
  value
})

export const setAssetModalOpen = (value) => ({
  type: "SET_ASSET_MODAL_OPEN",
  value
})

export const setAssetModalAssetName = (value) => ({
  type: "SET_ASSET_MODAL_ASSET_NAME",
  value
})

export const setAssetModalAssetMarkup = (value) => ({
  type: "SET_ASSET_MODAL_ASSET_MARKUP",
  value
})

export const setAssetModalAssetColor = (value) => ({
  type: "SET_ASSET_MODAL_ASSET_COLOR",
  value
})

export const setAssetModalAssetBackgroundColor = (value) => ({
  type: "SET_ASSET_MODAL_ASSET_BACKGROUNDCOLOR",
  value
})

export const setAuthenticated = (value) => ({
  type: "SET_AUTHENTICATED",
  value
})

export const setNavbarStyle = (value) => ({
  type: "SET_NAVBAR_STYLE",
  value
})