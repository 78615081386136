import React from "react";
import { connect } from "react-redux";
import {
  setAssetModalOpen,
  setAssetModalAssetName,
  setAssetModalAssetMarkup,
  setAssetModalAssetColor,
  setAssetModalAssetBackgroundColor
} from "redux/actions";

const Download = ({ name, logoMarkup, color, backgroundColor, children, setDownloadAssetModal }) => {

  return (
    <div className="LogoColors-download" onClick={() => {
      setDownloadAssetModal({
        name: name,
        markup: logoMarkup,
        color: color.key,
        backgroundColor: backgroundColor.key
      })
    }}>
      {children}
    </div>
  )
}
const mapDispatchToProps = dispatch => ({
  setDownloadAssetModal: ({ name, markup, color, backgroundColor }) => {
    dispatch(setAssetModalAssetName(name))
    dispatch(setAssetModalAssetMarkup(markup))
    dispatch(setAssetModalAssetColor(color))
    dispatch(setAssetModalAssetBackgroundColor(backgroundColor))
    dispatch(setAssetModalOpen(true))
  }
})
export default connect(null,mapDispatchToProps)(Download);