import React from "react";
import "./index.scss";

export default ({ className="", heading, explainer, children }) => {
  return (
    <div className={`Section contain ${className}`}>
      <SectionHeader heading={heading} explainer={explainer} />
      <div className="Section-inner">
        {children}
      </div>
    </div>
  )
}

export const SectionHeader = ({ heading, explainer }) => {
  return (heading || explainer) ? (
    <div className="Section-header">
      {heading && <div className="Section-heading font-heading">{heading}</div>}
      {explainer && <div className="Section-explainer font-intro">{explainer}</div>}
    </div>
  ) : null
}
