import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setAuthenticated } from "redux/actions";
import "./index.scss";
import { ReactComponent as Right } from "assets/right.svg";
//import { ReactComponent as Logo } from "assets/Pete2020.svg";

const Login = ({ setAuthenticated, foregroundColor, backgroundColor }) => {
  const [passwordValue,setPasswordValue] = useState("")
  const [error,setError] = useState()
  const passwordRef = useRef()
  useEffect(() => {
    passwordRef.current.focus()
  },[passwordRef.current])

  return <div className="Login" style={{ backgroundColor }}>
    <div className="Login-logo" style={{ fill: foregroundColor }}>
      {/* <Logo /> */}
    </div>
    <form onSubmit={(e) => {
      e.preventDefault();
      const success = ["401smith","buddytruman2020"].includes(passwordValue);
      if (!success) {
        setError(true)
      }
      setAuthenticated(success)
      console.log('sub',success)
    }}>
      <div className="Login-inner">
        <label className="Login-password ff-industry">
          <input
            ref={passwordRef}
            type="password"
            value={passwordValue}
            placeholder={"Password"}
            onChange={(e) => {
              setError(false)
              setPasswordValue(e.target.value);
            }} />
          {error && <div className="Login-error">Incorrect password.</div>}
        </label>
        <button disabled={passwordValue.length === 0} className="Login-submit" type="submit"><Right /></button>
      </div>
    </form>
  </div>
}

const mapStateToProps = state => ({
  foregroundColor: state.data.color.items.skagen.hex,
  backgroundColor: state.data.color.items.claeys.hex
})
const mapDispatchToProps = dispatch => ({
  setAuthenticated: value => dispatch(setAuthenticated(value))
})
export default connect(mapStateToProps,mapDispatchToProps)(Login)