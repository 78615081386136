import { useEffect } from "react";
import { connect } from "react-redux";
import {
  setNavbarStyle
} from "redux/actions";

const SetNavbarStyle = ({ theme, setNavbarStyle }) => {
  useEffect(() => {
    setNavbarStyle(theme)
  }, [])
  return null
}

const mapDispatchToProps = (dispatch) => ({
  setNavbarStyle: (value) => {
    dispatch(setNavbarStyle(value))
  }
})
export default connect(null,mapDispatchToProps)(SetNavbarStyle);