import React from 'react';
import './App.scss';

import { Provider, connect } from 'react-redux';
import store from "./redux/store.js";

import { BrowserRouter, Route } from "react-router-dom";
import ReactTooltip from 'react-tooltip'

import NavBar from "components/NavBar";
import Logos from "components/Logos";
import Colors from "components/Colors";
import Type from "components/Type";
//import Messaging from "components/Messaging";
import ColorCombo from "components/ColorCombo";
import Section from "components/Section";
import AssetDownload from "components/AssetDownload";
import Login from "components/Login";
import Home from "components/Home";
import TeamPete from "components/TeamPete";

import data from "data.js";

const Root = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ConnectedApp />
      </BrowserRouter>
    </Provider>
  );
}

export default Root;

//For tooltip disable
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const App = ({ authenticated }) => {
  return (authenticated ? (
    <div className="App">
      <NavBar />
      <main>
        <Page
          Component={Home}
          slug={"/"} />
        <Page
          Component={Logos}
          slug={data.logo.slug} />
        <Page
          Component={Colors}
          slug={data.color.slug} />
        <Page
          Component={Type}
          slug={data.type.slug} />
        <Page
          Component={TeamPete}
          slug={"team"} />
        {/* <Page */}
        {/*   Component={Messaging} */}
        {/*   slug={data.messaging.slug} /> */}
        <Route
          exact path={`/sandbox`}
          render={({ match }) => (
            <Section>
              <ColorCombo showModes={true} />
            </Section>
          )} />
        <Route
          exact path={`/admin`}
          render={() => {
            window.location = "/api/admin";
          }} />
        <AssetDownload />
        <ReactTooltip
          className="Tooltip font-label"
          effect="solid"
          disable={ isMobile  }  />
      </main>
    </div>) : <Login />)
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated
})
const ConnectedApp = connect(mapStateToProps,null)(App);

const Page = ({ Component, slug }) => {
  return (
    <Route path={`/${slug}`} component={Component} />
  )
}

// const PrivateRoute = ({ authenticated=false, component: Component, ...rest }) => {
//   return <Route {...rest} render={props => {
//     return authenticated ? <Component {...props} /> :
//     <Redirect to="/login" />
//   }} />
// }