const defaultState = {
  modalIsOpen: false,
  assetName: "Pete Bridge 2020",
  assetMarkup: null,
  assetColor: "skagen",
  assetBackgroundColor: "strato"
}

export default (state=defaultState, action) => {
  switch (action.type) {
    case "SET_ASSET_MODAL_OPEN":
      return {
        ...state,
        modalIsOpen: action.value
      }

    case "SET_ASSET_MODAL_ASSET_NAME":
      return {
        ...state,
        assetName: action.value
      }

    case "SET_ASSET_MODAL_ASSET_MARKUP":
      return {
        ...state,
        assetMarkup: action.value
      }

    case "SET_ASSET_MODAL_ASSET_COLOR":
      return {
        ...state,
        assetColor: action.value
      }

    case "SET_ASSET_MODAL_ASSET_BACKGROUNDCOLOR":
      return {
        ...state,
        assetBackgroundColor: action.value
      }

    default:
      return state
  }
}