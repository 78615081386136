import React, { useEffect } from "react";
import "./index.scss";
import Section, { SectionHeader } from "components/Section";
//import { ReactComponent as Down } from "assets/down.svg";
import ColorCombo from "components/ColorCombo";
import {
  setSandboxMode
} from "redux/actions";
import { connect } from "react-redux";

import ScrollToTopOnMount from "components/ScrollToTopOnMount";
import SetNavbarStyle from "components/SetNavbarStyle";

const Logos = ({ data, colors, setSandboxMode }) => {
  useEffect(() => {
    setSandboxMode('logo')
  },[])

  // const logoGroups = data.items.reduce((result,item) => {
  //   if (!result[item.group]) {
  //     result[item.group] = []
  //   }
  //   result[item.group].push(item);
  //   return result
  // },{})

  return (
    <div>
      <ScrollToTopOnMount />
      <SetNavbarStyle theme="page" />
      <div className="contain">
        <SectionHeader heading={data.heading} explainer={data.explainer} />
      </div>
      <Section>
        <ColorCombo />
      </Section>
    </div>
  )
}

// <div className="Logos">
//   {Object.keys(logoGroups).map(group => {
//     return <div className="Logos-section" key={group}>
//       <div className="Logos-section-heading font-subheading">{group.charAt(0).toUpperCase() + group.slice(1)}</div>
//       <div className="Logos-section-grid">
//         {logoGroups[group].map(logo => <Logo key={logo.name} {...logo} />)}
//       </div>
//     </div>
//   })}
// </div>
// </Section>

const mapStateToProps = state => ({
  data: state.data.logo
})
const mapDispatchToProps = (dispatch) => ({
  setSandboxMode: (value) => dispatch(setSandboxMode(value)),
})
export default connect(mapStateToProps,mapDispatchToProps)(Logos);

// const Logo = ({
//   name,
//   Component,
//   src,
//   size
// }) => {
//   return (
//     <div className={`Logo Logo--${size}`}>
//       <a href={src} download target="_blank" rel="noopener noreferrer">
//         <div className="Logo-inner">
//           <div className="Logo-image hover--scale">
//             <Component />
//           </div>
//           <div className="Logo-title font-label">{name}</div>
//         </div>
//       </a>
//       <div className="hover-mark font-action" style={{ color: "#CECECE" }}><div>Download </div><Down stroke={"#CECECE"} /></div>
//     </div>
//   )
// }

// const LogosColors = () => <div className="LogosColors" >
//   {Object.values(colors).map(color => {
//     return color.pairings.map(pairing => {
//       const style = {
//         backgroundColor: color.hex,
//         fill: colors[pairing].hex
//       }
//       return <div
//         key={color.key + colors[pairing].key}
//         className="LogosColors-row"
//         style={{
//         gridTemplateColumns: `repeat(${data.items.length},1fr)`
//       }}>
//         {data.items.map(logo => {
//           return (
//             <DownloadSvg
//               key={logo.name}
//               name={logo.name}
//               color={colors[pairing]}
//               backgroundColor={color}>
//               <logo.Component style={style} />
//               <div className="hover-mark font-action">
//                 <Down stroke={style.fill} />
//               </div>
//             </DownloadSvg>
//           )
//         })}
//       </div>
//     });
//   })}
// </div>