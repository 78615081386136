import React from "react";
import { useSpring, animated } from "react-spring";

export default ({ open=false }) => {
  const [t1,setT1] = useSpring(() => ({
    transform: "translate(0px,0px) rotate(0deg)"
  }))
  setT1({
    transform: open ? `translate(18px, -9px) rotate(45deg)` : "translate(0px,0px) rotate(0deg)"
  })
  const [t2,setT2] = useSpring(() => ({
    transform: "translate(0px,0px) rotate(0deg)"
  }))
  setT2({
    transform: open ? `translate(-17px, 6px) rotate(-45deg)` : "translate(0px,0px) rotate(0deg)"
  })
  const [t3,setT3] = useSpring(() => ({
    opacity: 0,
    transform: `translateY(0px)`
  }))
  setT3({
    opacity: open ? 0 : 1,
    transform: `translateY(${open ? '10px' : '0px'})`
  })

  return (
    <svg width="31px" height="31px" viewBox="0 0 31 31">
      <g fill="#064C81" strokeWidth="1">
        <animated.polygon points="0 21 31 21 31 17 0 17" style={t1}></animated.polygon>
        <animated.polygon points="0 31 31 31 31 27 0 27" style={t2}></animated.polygon>
        <animated.polygon
          style={t3}
          points="16 2.48705157 12.2917693 0 13.5999771 4.16775741 10 6.79845623 14.516639 6.88711782 16 11 17.4832465 6.88711782 22 6.79845623 18.4000229 4.16775741 19.7082307 0"></animated.polygon>
      </g>
    </svg>
  )
}