import React, { useEffect, useState } from "react";
import Asset from "./Asset.js";
import "./index.scss";
import Section from "components/Section";

export default () => {

  return (
    <Section heading="Team Pete">
      <Assets order="alphabetical" />
    </Section>
  )
}

const Assets = ({ order }) => {
  const [items, setItems] = useState(null)
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/brandassets.json`)
      .then(r=>r.json())
      .then(({ data }) => {
        if (order) {
          switch (order) {
            case 'alphabetical':
              data = data.sort((a,b) => {
                if(a.title < b.title) { return -1; }
                if(a.title > b.title) { return 1; }
                return 0;
              })
              break;
            default:
          }
        }
        setItems(data)
      })
  },[])

  return items ? (
    <div className="Assets">
      {items.map(item => <Asset key={item.id} {...item} />)}
    </div>
  ) : null
}