const defaultState = {
  navBar: 'home'
}

export default (state=defaultState, action) => {
  switch (action.type) {
    case "SET_NAVBAR_STYLE":
      return {
        ...state,
        navBar: action.value
      }

    default:
      return state
  }
}