import React from "react";
import Hero from "components/Hero";
import Nav from "components/Nav";
import ScrollToTopOnMount from "components/ScrollToTopOnMount";
import SetNavbarStyle from "components/SetNavbarStyle";

const Home = () => {
  return (
    <div className="Home">
      <ScrollToTopOnMount />
      <SetNavbarStyle theme="home" />
      <Hero />
      <Nav />
    </div>
  )
}

export default Home;