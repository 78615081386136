import React, { useState, useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { connect } from "react-redux";
import { setSandboxLogo } from "redux/actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./index.scss";
import DownloadSvg from "components/DownloadSvg";
import { ReactComponent as Down } from "assets/down.svg";
import Tooltip from "react-tooltip";

const DEFAULT_TEXT_ITEMS = [
  { Component: () => <div className="SandboxSample-samples-item font-label">
    <span className="ff-industry">Generational Change</span>
  </div>},
  { Component: () => <div className="SandboxSample-samples-item font-hero-heading">For The heart of America</div> },
  { Component: () => <div className="SandboxSample-samples-item font-subheading">
    Freedom, Democracy, Security
  </div>},
  { Component: () => <div className="SandboxSample-samples-item font-intro">
    It's time for a new generation of American leadership in this country.
  </div>},
  { Component: () => <div className="SandboxSample-samples-item font-body">
    <span className="ff-domaine">Changemaker, natural resources, progress social capital; dynamic inspirational changemaker. Human-centered mobilize, strengthening infrastructure boots on the ground social innovation changemaker human-centered energize NGO. Thought leader global boots on the ground thought leader emerging.</span>
  </div>}
];

const SandboxSample = ({
  sampleType,
  color,
  secondaryColor,
  backgroundColor,
  sandboxLogo,
  logoItems,
  logos,
  setLogoItems
}) => {

  const [textItems,setTextItems] = useState(DEFAULT_TEXT_ITEMS)

  const [items,setItems] = useState(textItems);
  const [choices,setChoices] = useState();
  const [actions,setActions] = useState();

  const style = {
    backgroundColor: backgroundColor.hex,
    color: color.hex
  }

  useEffect(() => {
    switch (sampleType) {
      case "text":
        setItems(textItems)
        setChoices(null)
        setActions(null)
        break;

      case "logo":
        setItems(logoItems)
        setChoices(<LogoChoices
          setItems={setItems}
          setLogoItems={setLogoItems}
          logos={logos}
          logoStyle={style} />
        )
        break;
      default:
    }
  }, [sampleType, items, color.hex, backgroundColor.hex, secondaryColor])

  useEffect(() => {
    switch (sampleType) {
      case "text":
        setActions(null)
        break;

      case "logo":
        if (items.length) {
          setActions(
            <LogoActions
              logo={items[0]}
              logoStyle={style}
              actionsStyle={{
                backgroundColor: backgroundColor.hex,
                color: color.hex,
                stroke: color.hex
              }}
              color={color}
              secondaryColor={secondaryColor}
              backgroundColor={backgroundColor}
              colorName={color.key}
              secondaryColorName={secondaryColor ? secondaryColor.key : null}
              backgroundColorName={backgroundColor.key}
              stroke={color.hex} />
          )
        }
        break;
      default:
    }

    Tooltip.rebuild()

  }, [items, color.hex, backgroundColor.hex, secondaryColor])

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
    switch (sampleType) {
      case "text":
        setTextItems(newItems)
        break;

      case "logo":
        setLogoItems(newItems)
        break;
      default:
    }
  }

  function reorder (list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  return (
    <div
      className="SandboxSample"
      style={style}>
      {choices}
      <style dangerouslySetInnerHTML={{ __html: `
        .SandboxSample {
          fill: ${color.hex};
        }
        ${secondaryColor && `
          .SandboxSample #secondary {
            fill: ${secondaryColor.hex};
          }
        `}
      `}} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className="SandboxSample-samples"
              ref={provided.innerRef}
              {...provided.droppableProps}>
              {items.map((item, index) => {
                return (
                  <Draggable key={index} draggableId={"ITEM" + index} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <item.Component />
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {actions}
    </div>
  )
}

const mapStateToProps = state => {
  const colors = state.data.color.items;
  return {
    logoItems: state.sandbox.logo,
    logos: state.data.logo.items,
    color: colors[state.sandbox.foregroundColor],
    secondaryColor: colors[state.sandbox.secondaryColor],
    backgroundColor: colors[state.sandbox.backgroundColor],
    sampleType: state.sandbox.mode
  }
}
const mapDispatchToProps = dispatch => ({
  setLogoItems: (value) => {
    dispatch(setSandboxLogo(value))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(SandboxSample);

const LogoChoices = ({ logos, setItems, setLogoItems, logoStyle, choicesStyle }) => {
  return <div className="SandboxSample-choices" style={choicesStyle}>
    {logos.map(logo => (
      <div key={logo.name} data-tip={logo.name} className="SandboxSample-choices-choice" onClick={() => {
        setItems([logo])
        setLogoItems([logo])
    }}>
        <logo.Component style={logoStyle} />
      </div>
    ))}
  </div>
}


const viewBoxDimensions = 392.6;
const LogoActions = ({
  logo,
  color,
  secondaryColor,
  backgroundColor,
  // colorName,
  // secondaryColorName,
  // backgroundColorName,
  actionsStyle,
  logoStyle,
  stroke
}) => {
  const [hovered,setHovered] = useState(false)
  function onMouseEnter() {
    setHovered(true)
  }
  function onMouseLeave() {
    setHovered(false)
  }
  const actionsStyleHovered = {
    color: actionsStyle.backgroundColor,
    fill: actionsStyle.backgroundColor,
    backgroundColor: actionsStyle.color,
  }
  const style = hovered ? actionsStyleHovered : actionsStyle;


  //const logo = data.logo.items.find(item => item.name === assetName)
  const StyledComponent = <logo.Component id="Download" xmlns="http://www.w3.org/2000/svg" />
  const staticMarkup = renderToStaticMarkup(StyledComponent);

  const splitStaticMarkup = staticMarkup.split(/(.*?)><(.*)/);
  const markup = splitStaticMarkup[1] + `
    >
      <style>
        svg#Download {
          background-color: ${backgroundColor.hex};
          fill: ${color.hex};
        }
        ${secondaryColor ? `
          svg#Download #secondary {
            fill: ${secondaryColor.hex};
          }
        `: ''}
      </style>
      <rect x="0" y="0" width="${viewBoxDimensions}" height="${viewBoxDimensions}" fill="${backgroundColor.hex}" />
    <`.trim() + splitStaticMarkup[2];

//name={logo.name}
  return (
    <div
      className="SandboxSample-actions"
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <DownloadSvg
        name={logo.name}
        logoMarkup={markup}
        color={color.name}
        backgroundColor={backgroundColor.name}>
        <div className="font-action" style={{
          stroke: style.color
        }}>Download <Down /></div>
      </DownloadSvg>
    </div>
  )
}