import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./index.scss";
import { useTransition, animated } from "react-spring";
import { Link } from "react-router-dom";

import { useSpring } from "react-spring";

import { setNavbarStyle } from "redux/actions"

import { ReactComponent as Pete } from "assets/Pete2020.svg";
import Menu from "./menu.js";

import { TextNav as Nav } from "components/Nav";

const NavBar = ({ data, colors, navbarTheme, pageStyle, setNavbarStyle }) => {
  const {
    heartland,
    skagen,
    strato,
    claeys
  } = colors;
  const themes = {
    page: {
     backgroundColor: strato.hex,
     fill: claeys.hex,
     color: claeys.hex
    },
    home: {
      backgroundColor: heartland.hex,
      fill: skagen.hex,
      color: skagen.hex
    },
    menu: {
      backgroundColor: claeys.hex,
      fill: strato.hex,
      color: strato.hex
    }
  }

  const [menuOpen,setMenuOpen] = useState(false);
  const transitions = useTransition(menuOpen,null, {
    from: {
      transform: "translateY(-100%)"
    },
    enter: {
      transform: "translateY(0%)"
    },
    leave: {
      transform: "translateY(-100%)"
    },
    unique: true
  })

  useEffect(() => {
    setStyle(themes[menuOpen ? "menu" : navbarTheme])
  }, [menuOpen,navbarTheme])

  function closeMenu() {
    setMenuOpen(false)
  }

  const [style,setStyle] = useSpring(() => ({
    from: themes[navbarTheme]
  }))

  return (
    <div className="NavBar">
      <animated.div className="NavBar-bar" style={style}>
        <button className="NavBar-toggle" onClick={() => { setMenuOpen(!menuOpen) }}>
          <Menu open={menuOpen} />
        </button>
        <Link to="/" className="NavBar-bar-homelink" onClick={closeMenu}><Pete /></Link>
      </animated.div>
      {transitions.map(({ item, props, key }) => item && <animated.div key={key} style={props} className="NavBar-menu">
        <animated.div style={style}>
          <Nav data={data} onClick={closeMenu} />
        </animated.div>
      </animated.div>)}
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.data,
  colors: state.data.color.items,
  navbarTheme: state.style.navBar
})
const mapDispatchToProps = dispatch => ({
  setNavbarStyle: (value) => {
    dispatch(setNavbarStyle(value))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(NavBar)