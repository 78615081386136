import React from "react";
//import LogoMorph from "components/LogoMorph";
import { animated } from "react-spring";
import "./index.scss";

import data from "data.js";

const style = {
  backgroundColor: data.color.items.heartland.hex,
  fill: data.color.items.skagen.hex,
  color: data.color.items.skagen.hex
}

export default () => {
  return (
    <animated.div className="Hero" style={style}>
      {//<LogoMorph backgroundColor={backgroundColor} fill={fill} />
      }
      <div className="Hero-heading">
        <h1 className="font-hero-heading">For Pete's Sake</h1>
        <div className="font-subheading">Brand Guidelines</div>
      </div>
    </animated.div>
  )
}