import React from "react";
import PeteBridge2020Src, { ReactComponent as PeteBridge2020 } from "assets/logos/Pete_Bridge_2020.svg";

import PeteBridgeSrc, { ReactComponent as PeteBridge } from "assets/logos/Pete_Bridge.svg";
import PeteFullnameSrc, { ReactComponent as PeteFullname } from "assets/logos/Pete_Buttigieg.svg";

//import PeteSrc, { ReactComponent as Pete } from "assets/logos/Pete.svg";
import VotePete2020Src, { ReactComponent as VotePete2020 } from "assets/logos/Vote_Pete_2020.svg";
import PickPete2020Src, { ReactComponent as PickPete2020 } from "assets/logos/Pick_Pete_2020.svg";
import TeamPete2020Src, { ReactComponent as TeamPete2020 } from "assets/logos/Team_Pete_2020.svg";

import BootEdgeEdgeSrc, { ReactComponent as BootEdgeEdge } from "assets/logos/Boot_Edge_Edge.svg";
import DemocratForPresidentSrc, { ReactComponent as DemocratForPresident } from "assets/logos/Democrat_For_President.svg";

import stratoStory from "assets/colorstory/Strato.jpg";
import claeysStory from "assets/colorstory/Claeys.jpg";
import riverStory from "assets/colorstory/River.jpg";
import heartlandStory from "assets/colorstory/Heartland.jpg";
import skyStory from "assets/colorstory/Calm.jpg";
import rustStory from "assets/colorstory/RustandSky.jpg";
import dogStory from "assets/colorstory/BuddyTruman.jpg"

import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as Type } from "assets/type.svg";
import { ReactComponent as Color } from "assets/color.svg";
//import { ReactComponent as Messaging } from "assets/messaging.svg";
const data = {
  logo: {
    slug: "logo",
    heading: "Logo",
    explainer: "We’ve got tons of ways for you to show your support for Pete! Use our color pairing tool to select approved color combinations for all of our campaign logos and download them in JPG, PNG or SVG format.",
    items: [
      {
        name: "Pete Bridge 2020",
        Component: PeteBridge2020,
        src: PeteBridge2020Src,
        size: 'full',
        group: 'Primary Logo',
        twotone: true
      },
      {
        name: "Pete Bridge",
        Component: PeteBridge,
        src: PeteBridgeSrc,
        size: 'half',
        group: 'Logo Variations'
      },
      {
        name: "Pete Buttigieg",
        Component: PeteFullname,
        src: PeteFullnameSrc,
        size: 'half',
        'group': 'Logo Variations'
      },
      // {
      //   name: "Pete",
      //   Component: Pete,
      //   src: PeteSrc,
      //   size: 'half',
      //   group: 'Logo Variations'
      // },
      // {
      //   name: "Pete 2020",
      //   Component: Pete2020,
      //   src: Pete2020Src,
      //   size: 'third',
      //   group: 'Logo Variations'
      // },
      {
        name: "Vote Pete 2020",
        Component: VotePete2020,
        src: VotePete2020Src,
        size: 'third',
        group: 'Logo Variations'
      },
      {
        name: "Team Pete 2020",
        Component: TeamPete2020,
        src: TeamPete2020Src,
        size: 'third',
        group: 'Logo Variations'
      },
      {
        name: "Pick Pete 2020",
        Component: PickPete2020,
        src: PickPete2020Src,
        size: 'third',
        group: 'Logo Variations'
      },
      {
        name: "Boot Edge Edge",
        Component: BootEdgeEdge,
        src: BootEdgeEdgeSrc,
        size: 'half',
        group: 'Secondary Logos'
      },
      {
        name: "Democrat For President",
        Component: DemocratForPresident,
        src: DemocratForPresidentSrc,
        size: 'half',
        group: 'Secondary Logos'
      },
      // {
      //   name: "PB 2020 Horizontal",
      //   Component: PB2020Horizontal,
      //   src: PB2020HorizontalSrc,
      //   size: 'third',
      //   group: 'Secondary Logos'
      // },
      // {
      //   name: "PB for President 2020",
      //   Component: PBPresident2020,
      //   src: PBPresident2020Src,
      //   size: 'third',
      //   group: 'Secondary Logos'
      // },
      // {
      //   name: "PB 2020 Vertical",
      //   Component: PB2020Vertical,
      //   src: PB2020VerticalSrc,
      //   size: 'third',
      //   group: 'Secondary Logos'
      // }
    ],
    NavImage: Logo,
    navBackgroundColor: "strato",
    navForegroundColor: "clearSkies"
  },
  color: {
    slug: "color",
    heading: "Color",
    explainer: "Rather than adopt the default red, white and blue color palette of past presidential candidates, our color palette is deeply rooted in  Pete’s home town – South Bend, Indiana. Born and raised in South Bend, Mayor Pete has led the rust-belt, midwestern city through a period of rennaissance since he took office. The 9 colors in our pallette are an ode to Pete’s hometown and his life there.",
    contrastExplainer: "Colors are paired using high contrast combinations that have been approved for use based on USA web accessibility standards. Once choosing the forground color, compatible color options can be chosen for the background.",
    items: {
      strato: {
        key: "strato",
        name: "Strato Blue",
        role: "primary",
        hex: "#1d253c",
        cmyk: [77,68,42,27],
        pms: "655",
        foregrounds: [
          "claeys",
          ["claeys","buddy"],
          "heartland",
          "clearSkies",
          "calm",
          "buddy"
        ]
      },
      claeys: {
        key: "claeys",
        name: "Claeys Cream",
        hex: "#f2e4d6",
        cmyk: [0, 10, 12, 5],
        role: "primary",
        pms: "9225",
        foregrounds: [
          "strato",
          ["strato","buddy"],
          "skagen",
          ["skagen","buddy"],
          "rustBelt",
          "truman"
        ]
      },
      skagen: {
        key: "skagen",
        name: "River Blue",
        hex: "#004d80",
        cmyk: [94, 60, 28, 8],
        role: "primary",
        pms: "301",
        foregrounds: [
          "claeys",
          ["claeys","heartland"],
          "heartland",
          "clearSkies"
        ]
      },
      heartland: {
        key: "heartland",
        name: "Heartland Yellow",
        hex: "#f2ba42",
        cmyk: [5, 35, 85, 0],
        role: "primary",
        pms: "128",
        foregrounds: [
          "strato",
          ["strato","skagen"],
          "skagen",
          "truman"
        ]
      },
      clearSkies: {
        key: "clearSkies",
        name: "Calm Blue",
        hex: "#eef4f8",
        cmyk: [4, 2, 0, 3],
        role: "secondary",
        pms: "649",
        foregrounds: [
          "strato",
          "skagen",
          "calm",
          "rustBelt",
          "truman"
        ]
      },
      calm: {
        key: "calm",
        name: "Blue Sky",
        hex: "#85b6c5",
        cmyk: [32, 8, 0, 23],
        role: "secondary",
        pms: "7458",
        foregrounds: [
          "strato",
          ["strato","clearSkies"],
          "truman"
        ]
      },
      rustBelt: {
        key: "rustBelt",
        name: "Rust Belt",
        hex: "#d34e23",
        cmyk: [10, 71, 89, 1],
        role: "secondary",
        pms: "159",
        foregrounds: [
          "strato",
          "claeys",
          ["claeys","strato"],
          "clearSkies"
        ]
      },
      buddy: {
        key: "buddy",
        name: "Buddy Gold",
        hex: "#b88a57",
        cmyk: [24, 41, 59, 1],
        role: "secondary",
        pms: "7562",
        foregrounds: [
          "strato",
          "claeys",
          ["claeys","heartland"],
          "clearSkies"
        ]
      },
      truman: {
        key: "truman",
        name: "Truman Brown",
        hex: "#653727",
        cmyk: [0, 46, 61, 60],
        role: "secondary",
        pms: "725",
        foregrounds: [
          "claeys",
          ["claeys","heartland"],
          "heartland",
          "clearSkies",
          "calm",
          "buddy"
        ]
      }
    },
    NavImage: Color,
    navBackgroundColor: "clearSkies",
    colorStories: [
      {
        color: ["strato"],
        text: "Strato Blue was one of the available colors in the 1964 line of Studebakers, an American automobile manufacturer founded and headquartered in South Bend, Indiana in 1852. Studebaker closed its doors in 1967 but still forms an important part of the local identity as can be experienced at the Studebaker Museum. The redevelopment of a former Studebaker plant is a cornerstone of the city's Rennaissance District.",
        image: stratoStory
      },
      {
        color: ["claeys"],
        text: "Claeys is a family-owned company that makes Old-Fashioned American Hard Candy. Founded in a South Bend garage in 1919, Claeys Candy has been making high-quality treats in the city for 100 years.",
        image: claeysStory
      },
      {
        color: ["skagen"],
        text: "In 2015, the South Bend River Lights were unveiled over St. Joseph’s River to commemorate the city’s 150th Anniversary. The interactive public light sculpture is a symbol of the city’s rebirth under Mayor Pete’s watch.",
        image: riverStory
      },
      {
        color: ["heartland"],
        text: "Yellow is big in Indiana. It is prominently featured in both the state flag and in South Bend's modern new  flag – yet another symbol of the city's penchant for reinvention ushered in during Mayor Pete's tenure.  It might be seen as a reference to gold found in local streams or to its golden wheat fields, but to us Heartland Yellow represents innovation—different people coming together in the heart of America, building on our industrial heritage to bring new ideas to life for the 21st century economy.",
        image: heartlandStory
      },
      {
        color: ["clearSkies"],
        text: "Pete Buttigieg is unapologetically substantive yet salt-of the earth. He speaks plainly and intelligently about the everyday issues that matter to real everyday people. He is a rational thinker and a great communicator. Above all, he is a calming voice who can clearly explain how we can face the great challenges before us.",
        image: skyStory
      },
      {
        color: ["rustBelt","calm"],
        text: "Like many midwestern American cities, South Bend’s landscape is a window to its past. It’s weathered red brick structures are a testament to the region’s industrial heritage, its contrasting bright skies a persistent symbol of optimism and readiness to adapt to the changing winds of history.",
        credit: <div>Photos by <a href="https://www.jacobtitus.co/" target="_blank" rel="noopener noreferrer">Jacob Titus</a></div>,
        image: rustStory
      },
      {
        color: ["buddy","truman"],
        text: "Buddy and Truman Buttigieg are the first dogs of South Bend and an important part of Pete and his husband Chasten’s family. You can learn all about the lovable pooches on their lively twitter account.",
        image: dogStory
      }
    ]
  },
  type: {
    slug: "type",
    heading: "Type",
    explainer: "Our brand typography pays homage to America’s industrial legacy (Industry), to Pete’s ability to convey substantive ideas plainly (Domaine Text), and to his bold vision for a modern America that reaches for the future with focus and clarity (Aktiv Grotesk Extended).",
    items: [
      {
        name: "Aktiv Grotesk Ext.",
        className: "ff-aktiv",
        explainer: "The primary face is a 21st century interpretation of grotesque sans, a ubiquitous sans serif font. In our application it is being used extended to bring a fresh persepctive to the characters and also inspired by some old Studebaker type treatments.",
        credit: "by Dalton Maag"
      },
      {
        name: "Industry",
        className: "ff-industry",
        explainer: "The secondary font is called Indstry and speaks for itself. The condensed letters are blocked off to mimic a manufacturing-inspired aesthetic in a contemporary execution.",
        credit: " by Mattox Shuler from Fort Foundry"
      },
      {
        name: "Domaine Text",
        className: "ff-domaine",
        explainer: "The tertiary face is Domaine Text, which acts as a sophisticated companion and allows for readability of large bodies of copy.",
        credit: "by Klim Type Foundry"
      }
    ],
    styles: [
      {
        style: "label",
        ipsum: "Generational Change",
        className: "font-label",
        meta: {
          family: "Industry",
          capitalization: "All Caps",
          weight: "Bold (700)",
          size: "1.1x",
          lineHeight: "1",
          tracking: "1.2"
        }
      },
      {
        style: "h1",
        ipsum: "Pete For the Heart of America",
        className: "font-hero-heading",
        meta: {
          family: "Aktiv Grotesk Ext.",
          capitalization: "All Caps",
          weight: "Bold (700)",
          size: "4x",
          lineHeight: "1.1",
          tracking: "1.3"
        },
      },
      {
        style: "h2",
        ipsum: "Freedom, Democracy, Security",
        className: "font-subheading",
        meta: {
          family: "Aktiv Grotesk Ext.",
          capitalization: "Sentence Case",
          weight: "Bold (700)",
          size: "1.3x",
          lineHeight: "1.1",
          tracking: "1.3"
        }
      },
      {
        style: "Intro",
        ipsum: "It's time for a new generation of American leadership in this country.",
        className: "font-intro",
        meta: {
          family: "Aktiv Grotesk Ext.",
          capitalization: "Sentence Case",
          weight: "Medium (500)",
          size: "1.3x",
          lineHeight: "1.1",
          tracking: "1.3"
        }
      },
      {
        style: "body",
        ipsum: "Social entrepreneurship circular thought provoking rubric, problem-solvers engaging relief thought leadership. Shared unit of analysis, grit venture philanthropy transparent external partners. Deep dive empower, thought leadership, relief preliminary thinking mass incarceration transparent inspiring sustainable. Game-changer invest, shine deep dive engaging corporate social responsibility black lives matter peaceful mobilize.",
        className: "font-body",
        meta: {
          family: "Domaine Text",
          capitalization: "Sentence Case",
          weight: "Normal (400)",
          size: "1x",
          lineHeight: "1.67",
          tracking: null
        }
      }
    ],
    NavImage: Type,
    navBackgroundColor: "skagen"
  },
  // messaging: {
  //   slug: "messaging",
  //   heading: "Messaging",
  //   navBackgroundColor: "claeys",
  //   navForegroundColor: "skagen",
  //   NavImage: Messaging,
  //   items: [
  //   {
  //       heading: "Taglines",
  //       text: <><p>Confident: We are self-assured in our 30-year track record, and certain of the strength of our future.</p>
  //         <p>Accessible: Our messages are welcoming and inclusive. We are straightforward and clear about our ideas, and avoid industry jargon and coded language.</p>
  //         <p>Dynamic: We are energized by our momentum, and are active leaders in shaping an equitable and sustainable world.</p></>
  //     },
  //     {
  //       heading: "#tags",
  //       text: <ul>
  //         <li>
  //           <a href="https://twitter.com/search?src=typd&q=%23PeteForAmerica" target="_blank" rel="noopener noreferrer">
  //             #PeteForAmerica
  //           </a>
  //         </li>
  //         <li>
  //           <a href="https://twitter.com/search?src=typd&q=%23Pete2020" target="_blank" rel="noopener noreferrer">
  //             #Pete2020
  //           </a>
  //         </li>
  //         <li>
  //           <a href="https://twitter.com/search?src=typd&q=%23BootEdgeEdge" target="_blank" rel="noopener noreferrer">
  //             #BootEdgeEdge
  //           </a>
  //         </li>
  //       </ul>
  //     },
  //     {
  //       heading: "Key Messages",
  //       text: <><p>Confident: We are self-assured in our 30-year track record, and certain of the strength of our future.</p>
  //         <p>Accessible: Our messages are welcoming and inclusive. We are straightforward and clear about our ideas, and avoid industry jargon and coded language.</p>
  //         <p>Dynamic: We are energized by our momentum, and are active leaders in shaping an equitable and sustainable world.</p></>
  //     },
  //     {
  //       heading: "Tone",
  //       text: <><p>Practical, with a wink. We work to provide the right information at the right time. (Having "just enough" is better than "all the things".) We don't take ourselves overly seriously. We know humor makes life more enjoyable, and our messages more approachable. (But we don't force it. It's more about being "human" than being "humorous.")</p></>
  //     }
  //   ]
  // }
}

export default data