import data from "data.js";
const defaultState = {
  ...data
}

export default (state=defaultState, action) => {
  switch (action.type) {
    default:
      return state
  }
}