import React, { useEffect } from "react";
import ColorPicker from "components/ColorPicker";
import SandboxSample from "components/SandboxSample";
import "./index.scss";
import Tooltip from "react-tooltip";

import {
  setSandboxMode,
  setSandboxForegroundColor,
  setSandboxSecondaryColor,
  setSandboxBackgroundColor
} from "redux/actions";
import { connect } from "react-redux";

import { ReactComponent as LogoIcon } from "assets/logo_icon.svg";
import { ReactComponent as TypeIcon } from "assets/type_icon.svg";

const ColorCombo = ({
  colors,
  logos,
  mode,
  setMode,
  showModes,
  foregroundColor,
  setForegroundColor,
  secondaryColor,
  backgroundColor,
  setBackgroundColor
}) => {

  useEffect(() => {
    if (!backgroundColor.foregrounds.includes(foregroundColor.key)) {
      const newForegroundColor = Object.values(colors).find(d => {
        return d.foregrounds.includes(foregroundColor.key)
      }).key
      setForegroundColor([newForegroundColor])
    }
  },[backgroundColor])

  useEffect(() => {
     if (mode !== "logo" && secondaryColor) {
      setForegroundColor([foregroundColor.key])
     }
  },[mode])

  useEffect(() => {
    Tooltip.rebuild()
  }, [backgroundColor])

  return (
    <div className="ColorCombo">
      <div className="ColorCombo-inner">
        <div className="ColorCombo-sandbox">
          <SandboxSample />
        </div>
        <div className="ColorCombo-controls-color">
          {showModes && <div className="ColorCombo-controls-control">
            <div className="ColorCombo-controls-control-label font-label">Content</div>
            <div className="ColorCombo-controls-modes">
              <ModeButton
                tooltip="Logo"
                onClick={() => setMode("logo")}
                Icon={<LogoIcon />}
                isSelected={mode === "logo"} />
              <ModeButton
                tooltip="Typeography"
                onClick={() => setMode("text")}
                Icon={<TypeIcon />}
                isSelected={mode === "text"} />
            </div>
          </div>}
          <div className="ColorCombo-controls-color-control">
            <div className="ColorCombo-controls-control-label font-label">Background</div>
            <ColorPicker
              enabled={Object.keys(colors)}
              selectedKey={backgroundColor.key}
              setSelected={setBackgroundColor}
              colors={colors}
              mode={mode} />
          </div>
          <div className="ColorCombo-controls-color-control">
            <div className="ColorCombo-controls-control-label font-label">Foreground</div>
            <ColorPicker
              enabled={backgroundColor.foregrounds}
              selectedKey={foregroundColor.key + (secondaryColor ? secondaryColor.key : '')}
              setSelected={setForegroundColor}
              colors={colors}
              mode={mode} />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const colors = state.data.color.items;
  return {
    mode: state.sandbox.mode,
    colors,
    logos: state.data.logo.items,
    foregroundColor: colors[state.sandbox.foregroundColor],
    secondaryColor: colors[state.sandbox.secondaryColor],
    backgroundColor: colors[state.sandbox.backgroundColor]
  }
}
const mapDispatchToProps = dispatch => ({
  setMode: (value) => {
    dispatch(setSandboxMode(value))
  },
  setForegroundColor: (values) => {
    dispatch(setSandboxForegroundColor(values[0]))
    dispatch(setSandboxSecondaryColor(values[1] || null))
  },
  setBackgroundColor: (values) => {
    dispatch(setSandboxBackgroundColor(values[0]))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(ColorCombo);

const ModeButton = ({ Icon, tooltip, onClick, isSelected }) => {
  return <div className="ColorCombo-controls-modes-mode">
    <button
      data-tip={tooltip}
      className={`
        ColorCombo-controls-modes-mode-button
        ${isSelected ? "ColorCombo-controls-modes-mode-button--selected" : ""}
      `.trim()}
      onClick={onClick}>
      <div className="ColorCombo-controls-modes-mode-button-inner">
      {Icon}
      </div>
    </button>
  </div>
}