import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import "./index.scss";
import tinycolor from "tinycolor2";

import data from "data.js"

export default ({...rest}) => {
  return (
    <nav {...rest} className="Nav">
      {Object.keys(data).map(d => <NavItem key={d} data={data} {...data[d]} />)}
    </nav>
  )
}

export const TextNav = ({ data, ...rest }) => {
  return (
    <nav {...rest} className="TextNav">
      {Object.keys(data).map(d => (
        <Link className="font-heading"
          key={d}
          to={`/${data[d].slug}`}>
          {data[d].heading}
        </Link>))}
    </nav>
  )
};

const NavItem = ({ slug, heading, navBackgroundColor, navForegroundColor, NavImage, data }) => {
  const [hovered,setHovered] = useState(false)
  function onMouseEnter(e) {
    setHovered(true)
  }
  function onMouseLeave() {
    setHovered(false)
  }
  const transition = useTransition(hovered,null,{
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    unique: true
  })
  return <Link to={`/${slug}`}>
    <div
      className="Nav-item"
      onPointerEnter={onMouseEnter}
      onPointerLeave={onMouseLeave}
      style={{
        backgroundColor: navBackgroundColor ? data.color.items[navBackgroundColor].hex : null,
        fill: navForegroundColor ? data.color.items[navForegroundColor].hex : null
      }}>
      {transition.map(({ item, props, key }) => item ?
        <animated.div key={"text"} style={{
          ...props,
          color: tinycolor(data.color.items[navBackgroundColor].hex).isLight() ? "#000" : "#fff"
        }} className="font-heading">{heading}</animated.div> :
        <animated.div key={"image"} style={props}>{NavImage ? <NavImage /> : heading}</animated.div>
      )}
    </div>
  </Link>
}