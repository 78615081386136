import React from "react";
import { connect } from "react-redux";
import "./index.scss";
import { useTransition, animated } from "react-spring";

const ColorPicker = ({ colors, selectedKey, setSelected, mode, enabled=[], logoItems }) => {
  const logoIsTwotone = logoItems[0].twotone;
  const filteredColors = enabled.reduce((r,d) => {
    if (Array.isArray(d)) {
      if (logoIsTwotone && mode==="logo") {
        r.push({
          key: d.join(""),
          name: d.map(d => colors[d].name).join(" and "),
          colors: d.map(d => colors[d].hex),
          keys: d
        })
      }
    } else {
      r.push({
        key: colors[d].key,
        keys: [colors[d].key],
        name: colors[d].name,
        colors: [colors[d].hex],
        foregrounds: colors[d].foregrounds
      })
    }
    return r
  },[])
  const transitions = useTransition(filteredColors, d=>d.key, {
    from: {
      opacity: 0,
      width: "0px"
    },
    enter: {
      opacity: 1,
      width: "70px",
      height: "70px"
    },
    leave: {
      display: 'none'
    }
  })

  return (
    <div className="ColorPicker">
      {transitions.map(({ item, props, key }) => {
        const isSelected = selectedKey === item.key;
        return (
          <animated.div className={`
            ColorPicker-color
            ${isSelected ? "ColorPicker-color--selected" : ""}
          `.trim()} style={props} key={key}>
            <div className="ColorPicker-color-inner">
              <Color setSelected={setSelected} color={item} />
            </div>
          </animated.div>
        )
      })}
    </div>
  )
}

const mapStateToProps = state => ({
  logoItems: state.sandbox.logo
})
export default connect(mapStateToProps)(ColorPicker)

const Color = ({ color, setSelected }) => {
  const { keys, name, colors } = color

  function onClick() {
    setSelected(keys)
  }
  return (
    <button
      onClick={onClick}
      data-tip={name}>
      <div className="ColorPicker-color-swatchwrap">
      {colors.map(color => (
        <div
          key={color}
          className="ColorPicker-color-swatch"
          style={{
            backgroundColor: (color === 'transparent' ? '#eee' : color)
          }} />
      ))}
      </div>
    </button>
  )
}