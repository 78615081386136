import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  setAssetModalOpen,
  setAssetModalAssetName,
  setAssetModalAssetMarkup,
  setAssetModalAssetColor,
  setAssetModalAssetBackgroundColor
} from "redux/actions";

const Asset = ({ id, title, assetUrl, authors, setDownloadAssetModal }) => {
  const [svgText,setSvgText] = useState()

  useEffect(() => {
    fetch(assetUrl)
      .then(r=>r.text())
      .then(text => {
        setSvgText(text);
      })
  },[])

  return (
    <div className="Assets-item" onClick={() => {
      setDownloadAssetModal({
        name: title,
        markup: svgText,
        color: null,
        backgroundColor: null
      })
    }}>
      {/* <img src={assetUrl} alt="" /> */}
      {svgText && <div dangerouslySetInnerHTML={{ __html: svgText }} />}
      <div>
        <span>{title} </span>
        {authors.length > 0 && <span>by {authors.map(author => author.website ?
          <a key={author.name} href={author.website} target="_blank" rel="noopener noreferrer">{author.name}</a> :
          <span key={author.name}>{author.name}</span>
        )}</span>}
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  setDownloadAssetModal: ({ name, markup, color, backgroundColor }) => {
    dispatch(setAssetModalAssetName(name))
    dispatch(setAssetModalAssetMarkup(markup))
    dispatch(setAssetModalAssetColor(color))
    dispatch(setAssetModalAssetBackgroundColor(backgroundColor))
    dispatch(setAssetModalOpen(true))
  }
})
export default connect(null,mapDispatchToProps)(Asset);